import '../sass/featured-success-stories.scss';

(() => {
    const $featuredSuccessStories = document.querySelectorAll('.featured-success-stories');

    if (!$featuredSuccessStories) return;

    $featuredSuccessStories.forEach($featuredSuccessStory => {
        const $carouselSmall = $featuredSuccessStory.querySelector('.featured-success-stories__carousel-small');
        const $carouselLarge = $featuredSuccessStory.querySelector('.featured-success-stories__carousel-large');

        const $navBtnPrev = $featuredSuccessStory.querySelector('.featured-success-stories__nav-btn--prev');
        const $navBtnNext = $featuredSuccessStory.querySelector('.featured-success-stories__nav-btn--next');

        const initCarousels = () => {
            let carouselSmall = null;
            let carouselLarge = null;

            if ($carouselSmall) {
                carouselSmall = new Swiper($carouselSmall);
            }

            if ($carouselLarge) {
                carouselLarge = new Swiper($carouselLarge, {
                    navigation: {
                        nextEl: $navBtnNext ?? null,
                        prevEl: $navBtnPrev ?? null,
                    },
                    rewind: true,
                });
            }
        };

        initCarousels();
    });
})();
